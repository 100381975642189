export const environment = {
    production: false,
    brandCode: 'yakitatenokarubi',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyDxo8BT6gOah-OyjNxnjzUjW9OfqBRdJ-s',
        authDomain: 'mc-app-yakitatenokarubi-dev.firebaseapp.com',
        projectId: 'mc-app-yakitatenokarubi-dev',
        storageBucket: 'mc-app-yakitatenokarubi-dev.appspot.com',
        messagingSenderId: '395479670250',
        appId: '1:395479670250:web:9475e3b9db76d32afa6693',
        measurementId: 'G-VCFEDSSSLY',
    },
    hostingBaseURL: 'https://app-dev.yakitateno-karubi.jp',
    appScheme: 'yakitatenokarubi-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id6450351819',
        android: 'https://play.google.com/store/apps/details?id=jp.yakitateno_karubi.members',
    },
}
